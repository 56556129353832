<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div id="app">
    <p>Việc cần xem:
      <input size="100" type="text" v-model="viecxemluchao"  name="viecxemluchao"/>
      <br>
    </p>
    <table>
      <tbody>
        <tr>
          <th width="20%" align="left">Lần gieo</th>
          <th width="30%" align="left">Hào</th>
          <th width="30%" align="left">Âm Dương</th>
          <th width="20%" align="left">Động</th>
        </tr>
        <tr>
          <td>6</td>
          <td>Hào Thượng</td>
          <td>
            <select v-model="hao6">
              <option selected="selected" value="1">———</option>
              <option value="0">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" v-model="haodong6" value="1"></td>
        </tr>
        <tr>
          <td>5</td>
          <td>Hào Ngũ</td>
          <td>
            <select v-model="hao5">
              <option selected="selected" value="1">———</option>
              <option value="0">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" v-model="haodong5" value="1"></td>
        </tr>
        <tr>
          <td>4</td>
          <td>Hào Tứ</td>
          <td>
            <select v-model="hao4">
              <option value="1">———</option>
              <option selected="selected" value="1">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" v-model="haodong4" value="1"></td>
        </tr>
        <tr>
          <td>3</td>
          <td>Hào Tam</td>
          <td>
            <select v-model="hao3">
              <option value="1">———</option>
              <option selected="selected" value="1">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" v-model="haodong3" value="1"></td>
        </tr>
        <tr>
          <td>2</td>
          <td>Hào Nhị</td>
          <td>
            <select v-model="hao2">
              <option value="1">———</option>
              <option selected="selected" value="1">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" checked="" v-model="haodong2" value="1"></td>
        </tr>
        <tr>
          <td>1</td>
          <td>Hào Sơ</td>
          <td>
            <select v-model="hao1">
              <option value="1">———</option>
              <option value="0">—　—</option>
            </select>
          </td>
          <td><input type="checkbox" v-model="haodong1" value="1"></td>
        </tr>
      </tbody>
    </table>

    Ngày lập quẻ:<select v-model="day" width="10">
      <option value="0">Ngày lập quẻ</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15" selected="selected">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
      <option value="24">24</option>
      <option value="25">25</option>
      <option value="26">26</option>
      <option value="27">27</option>
      <option value="28">28</option>
      <option value="29">29</option>
      <option value="30">30</option>
      <option value="31">31</option>
    </select>
    <select v-model="month">
      <option value="0">Tháng sinh</option>
      <option value="1">Tháng 1</option>
      <option value="2" selected="selected">Tháng 2</option>
      <option value="3">Tháng 3</option>
      <option value="4">Tháng 4</option>
      <option value="5">Tháng 5</option>
      <option value="6">Tháng 6</option>
      <option value="7">Tháng 7</option>
      <option value="8">Tháng 8</option>
      <option value="9">Tháng 9</option>
      <option value="10">Tháng 10</option>
      <option value="11">Tháng 11</option>
      <option value="12">Tháng 12</option>
    </select>
    <select v-model="year">
      <option value="1955">1955</option>
      <option value="1956">1956</option>
      <option value="1957">1957</option>
      <option value="1958">1958</option>
      <option value="1959">1959</option>
      <option value="1960">1960</option>
      <option value="1961">1961</option>
      <option value="1962">1962</option>
      <option value="1963">1963</option>
      <option value="1964">1964</option>
      <option value="1965">1965</option>
      <option value="1966">1966</option>
      <option value="1967">1967</option>
      <option value="1968">1968</option>
      <option value="1969">1969</option>
      <option value="1970">1970</option>
      <option value="1971">1971</option>
      <option value="1972">1972</option>
      <option value="1973">1973</option>
      <option value="1974">1974</option>
      <option value="1975">1975</option>
      <option value="1976">1976</option>
      <option value="1977">1977</option>
      <option value="1978">1978</option>
      <option value="1979">1979</option>
      <option value="1980">1980</option>
      <option value="1981">1981</option>
      <option value="1982">1982</option>
      <option value="1983">1983</option>
      <option value="1984">1984</option>
      <option value="1985">1985</option>
      <option value="1986">1986</option>
      <option value="1987">1987</option>
      <option value="1988">1988</option>
      <option value="1989">1989</option>
      <option value="1990">1990</option>
      <option value="1991">1991</option>
      <option value="1992">1992</option>
      <option value="1993">1993</option>
      <option value="1994">1994</option>
      <option value="1995">1995</option>
      <option value="1996">1996</option>
      <option value="1997">1997</option>
      <option value="1998">1998</option>
      <option value="1999">1999</option>
      <option value="2000">2000</option>
      <option value="2001">2001</option>
      <option value="2002">2002</option>
      <option value="2003">2003</option>
      <option value="2004">2004</option>
      <option value="2005">2005</option>
      <option value="2006">2006</option>
      <option value="2007">2007</option>
      <option value="2008">2008</option>
      <option value="2009">2009</option>
      <option value="2010">2010</option>
      <option value="2011">2011</option>
      <option value="2012">2012</option>
      <option value="2013">2013</option>
      <option value="2014">2014</option>
      <option value="2015">2015</option>
      <option value="2016">2016</option>
      <option value="2017">2017</option>
      <option value="2018">2018</option>
      <option value="2019">2019</option>
      <option value="2020">2020</option>
      <option value="2021">2021</option>
      <option value="2022">2022</option>
      <option value="2023">2023</option>
      <option value="2024" selected="selected">2024</option>
      <option value="2025">2025</option>
      <option value="2026">2026</option>
      <option value="2027">2027</option>
      <option value="2028">2028</option>
      <option value="2029">2029</option>
      <option value="2030">2030</option>
    </select>
    Giờ lập quẻ: <select v-model="hour">
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22" selected="selected">22</option>
      <option value="23">23</option>
    </select>
    <select v-model="minute">
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9" selected="selected">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
      <option value="24">24</option>
      <option value="25">25</option>
      <option value="26">26</option>
      <option value="27">27</option>
      <option value="28">28</option>
      <option value="29">29</option>
      <option value="30">30</option>
      <option value="31">31</option>
      <option value="32">32</option>
      <option value="33">33</option>
      <option value="34">34</option>
      <option value="35">35</option>
      <option value="36">36</option>
      <option value="37">37</option>
      <option value="38">38</option>
      <option value="39">39</option>
      <option value="40">40</option>
      <option value="41">41</option>
      <option value="42">42</option>
      <option value="43">43</option>
      <option value="44">44</option>
      <option value="45">45</option>
      <option value="46">46</option>
      <option value="47">47</option>
      <option value="48">48</option>
      <option value="49">49</option>
      <option value="50">50</option>
      <option value="51">51</option>
      <option value="52">52</option>
      <option value="53">53</option>
      <option value="54">54</option>
      <option value="55">55</option>
      <option value="56">56</option>
      <option value="57">57</option>
      <option value="58">58</option>
      <option value="59">59</option>
    </select>
    <button @click="fetchData">Lập quẻ lục hào</button>
    <div v-html="dataHtml"></div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      responseData: null,
      dataHtml: "<div></div>",
      minute: '',
      haodong1: '',
      hao1: 1,
      haodong6: '',
      hao6: 1,
      haodong5: '',
      hao5: 1,
      haodong4: '',
      hao4: 1,
      haodong3: '',
      hao3: 1,
      haodong2: 1,
      hao2:0,
      day: '',
      month: 2,
      year: 2024,
      hour: '',
      viecxemluchao: '',
    };
  },
  methods: {
    async fetchData() {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
        "Content-Type": "application/x-www-form-urlencoded"
      }
      console.log(this.viecxemluchao);
      let bodyContent = `viecxemluchao=${this.viecxemluchao}&hao6=${this.hao6}${this.haodong6 ? '&haodong6='+this.haodong6 :''}&hao5=${this.hao5}${this.haodong5 ? '&haodong5='+this.haodong5 :''}&hao4=${this.hao4}${this.haodong4 ? '&haodong4='+this.haodong4 :''}&hao3=${this.hao3}${this.haodong3 ? '&haodong3='+this.haodong3 :''}&hao2=${this.hao2}${this.haodong2 ? '&haodong2='+this.haodong2 :''}&hao1=${this.hao1}${this.haodong1 ? '&haodong1='+this.haodong1 :''}&day=${this.day}&month=${this.month}&year=${this.year}&hour=${this.hour}&minute=${this.minute}&luchaosubmit=&submit=L%E1%BA%ADp+qu%E1%BA%BB+l%E1%BB%A5c+h%C3%A0o`;
      let reqOptions = {
        url: "https://hocvienlyso.org/boidich/luchao.php",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      }
      try {

        const response = await axios.request(reqOptions);
        let arrayOfWords = response.data.split('<img src="data:image');
        this.dataHtml = '<img src="data:image' + arrayOfWords[1].split('height="931" />')[0] + ' height="931" />';
      } catch (error) {
        // Handle any errors from the API request
        console.error('Error fetching data:', error);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
